import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IAngularMyDpOptions,
  IMyDateModel,
  IMyCalendarViewChanged,
} from "angular-mydatepicker";
import { SchedulerService } from "src/app/services/scheduler.service";
import { DataService } from "src/app/services/data.service";
import moment from 'moment';
import * as moment2 from "moment-timezone";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/services/loader.service";
import { TimeZoneService } from "src/app/services/timezone.service";
import { CommonService } from "src/app/services/common.service";
import { Slot, AvailabilitySlot } from "src/app/models/interface";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: "app-schedule-calender",
  templateUrl: "./schedule-calender.component.html",
  styleUrls: ["./schedule-calender.component.scss"],
})
export class ScheduleCalenderComponent implements OnInit, OnDestroy {
  MONTHS: Array<String> = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  public modelDate: IMyDateModel;
  timezones: Array<any> = moment2.tz.names(); // TimezoneList.timezone_list;    // ['IST','PDT','PST'];
  slots: Array<Slot> = [];
  selectedDate: Date = new Date();
  selectedDateShow: string = moment(new Date()).format("dddd, MMMM DD");
  callType: string = "spec";
  public selectTimezoneDropDown = false;
  selectedTimezone: string = moment2.tz.guess();
  selectedTimeSlot: Slot;
  validError: boolean = false;
  chooseAnotherSlot: boolean = false;
  monthTimeSlot: Array<{
    date: string;
    time: Slot[];
  }> = [];
  calendarView: Date = new Date();
  onlyOnce: boolean = true;
  isOutOfficeSlotActive = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    // dateRange: true,
    dateFormat: "dd.mm.yyyy",
    // disableHeaderButtons: true,
    inline: true,
    disableUntil: {
      year: this.calendarView.getFullYear(),
      month: this.calendarView.getMonth() + 1,
      day: this.calendarView.getDate() - 1,
    },
    disableSince: {
      year: this.calendarView.getFullYear(),
      month: this.calendarView.getMonth() + 1,
      day: this.calendarView.getDate(),
    },
  };
  searchTimeZone: string;
  @ViewChild("slotsWindow", { static: false }) slotsWindow: ElementRef;
  @ViewChild("rescheduleCallModal", { static: false })
  rescheduleCallModal: ModalDirective;
  uniqueError = "";
  hideButtons = false;
  skeletonLoaderVisible;
  skeletonInfoLoaderVisible;
  showActiveTimeRange = false;
  showAdditionalInfo = false;
  schedulerInfo: any;
  showShimmerMonth = moment(new Date()).format("MMM");
  showShimmerYear = moment(new Date()).format("YYYY");
  disabledSlots = [];
  webSocketConnected = false;
  takeUntilSubscription = new Subject();
  showSubTypeDdl = false;
  selectedSubTypeFilter = ''
  subTypeFilterList = [];
  enterprise_tag = false;
  @ViewChild('subTypeFilterContainer', {static: false}) subTypeFilterContainer;


  constructor(
    private router: Router,
    private schedulerService: SchedulerService,
    private appDataService: DataService,
    public loaderService: LoaderService,
    private _timeZoneService: TimeZoneService,
    private commonService: CommonService
  ) {
  }

  get slotsInOfficeHours() {
    if (
      this.commonService.officeHoursStartTime &&
      this.commonService.officeHoursEndTime
    ) {
      const date = moment(this.selectedDate).format("yyyy-MM-DD");
      const startTimeMoment = moment.tz(
        moment(
          date + " " + this.commonService.officeHoursStartTime,
          "YYYY-MM-DD HH:MM A"
        ).format("YYYY-MM-DD HH:mm:ss"),
        this.selectedTimezone
      );
      const endTimeMoment = moment.tz(
        moment(
          date + " " + this.commonService.officeHoursEndTime,
          "YYYY-MM-DD HH:MM A"
        ).format("YYYY-MM-DD HH:mm:ss"),
        this.selectedTimezone
      );
      return this.slots.filter((x: Slot) => {
        return (
          !!startTimeMoment.isSameOrBefore(
            moment(x.start_time).tz(this.selectedTimezone)
          ) &&
          moment(x.start_time).tz(this.selectedTimezone).isBefore(endTimeMoment)
        );
      });
    } else {
      return this.slots;
    }
  }

  get slotsOutOfficeHours() {
    if (
      this.commonService.officeHoursStartTime &&
      this.commonService.officeHoursEndTime
    ) {
      const date = moment(this.selectedDate).format("yyyy-MM-DD");
      const startTimeMoment = moment.tz(
        moment(
          date + " " + this.commonService.officeHoursStartTime,
          "YYYY-MM-DD HH:MM A"
        ).format("YYYY-MM-DD HH:mm:ss"),
        this.selectedTimezone
      );
      const endTimeMoment = moment.tz(
        moment(
          date + " " + this.commonService.officeHoursEndTime,
          "YYYY-MM-DD HH:MM A"
        ).format("YYYY-MM-DD HH:mm:ss"),
        this.selectedTimezone
      );
      return this.slots.filter((x: Slot) => {
        return !!(
          moment(x.start_time)
            .tz(this.selectedTimezone)
            .isBefore(startTimeMoment) ||
          endTimeMoment.isSameOrBefore(
            moment(x.start_time).tz(this.selectedTimezone)
          )
        );
      });
    } else {
      return [];
    }
  }

  scrollWindowSlide() {
    if (this.slots.length > 0 && this.slotsWindow) {
      setTimeout(() => {
        this.slotsWindow.nativeElement.scrollTo({
          behavior: "smooth",
          top: this.slotsWindow.nativeElement.scrollHeight,
        });
      }, 1000);
    }
  }

  additionalInfo() {
    const domainPattern = /@(builder\.ai|engineer\.ai|x\.builder\.ai)$/;
    if (this.appDataService.urlParameters.call_initiator == "b360") {
      this.showAdditionalInfo = true;
    } else if (domainPattern.test(this.appDataService.urlParameters.email)) {
      this.showAdditionalInfo = true;
    } else {
      this.showAdditionalInfo = false;
    }

    this.showActiveTimeRange =
      this.showAdditionalInfo && this.appDataService.urlParameters.manager_name
        ? true
        : false;
  }

  ngOnInit(): void {
    if(this.appDataService.urlParameters.enterprise_tag === "true") {
      this.enterprise_tag = true;
    }
    
    if(this.appDataService.urlParameters.subTypeFilter) {
      this.selectedSubTypeFilter = this.appDataService.urlParameters.subTypeFilter;
      this.subTypeFilterList = this.appDataService.urlParameters.subTypeFilters.split('_');
    }

    this.inItDisableSlotWebsocket();

    this.additionalInfo();
    if (!this.appDataService.urlParameters.token) {
      this.router.navigate(["invalid-access"]);
    }
    this.hideButtons =
      this.appDataService.urlParameters.see_slots == "true" ? true : false;
    if (this.appDataService.urlParameters.scheduled_by_meta) {
      let scheduleMeta = JSON.parse(
        this.appDataService.urlParameters.scheduled_by_meta
      );
      this.commonService.setScheduledByMetaBehavior(scheduleMeta);
    }
    let backButton = false;
    let d = new Date(); // Date object for Disable the calendar
    d.setDate(d.getDate() - 1);
    this.myDatePickerOptions = {
      dateFormat: "dd.mm.yyyy",
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
      disableSince: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
      inline: true,
    };
    if (
      this.appDataService.urlParameters &&
      this.appDataService.urlParameters.call_type
    ) {
      this.callType = this.appDataService.urlParameters.call_type;
    }

    if (this.commonService.availabilityObject) {
      this.selectedDate = new Date(
        this.commonService.availabilityObject.selectedDate
      );
      this.calendarView = new Date(
        this.commonService.availabilityObject.selectedDate
      );
      this.setDateShow();
      this.selectedTimezone = this.commonService.availabilityObject.timezone;
      this._timeZoneService.currentTimeZone = this.selectedTimezone;
      this.schedulerInfo = this.commonService.schedulerInfo;
      this.handleAdditionalInfo();
      //sessionStorage.removeItem('availabilitySlot');
      this.commonService.availabilityObject = null;
      backButton = true;
    }
    this.commonService.description_color = "#9B9FB1";

    this.commonService.callType = this.callType;

    this.modelDate = {
      isRange: false,
      singleDate: {
        date: {
          year: this.selectedDate.getFullYear(),
          month: this.selectedDate.getMonth() + 1,
          day: this.selectedDate.getDate(),
        },
      },
    };

    // currentMonth = this.MONTHS[this.selectedDate.getUTCMonth()];

    if (!backButton) {
      this.getInitAvailability();
      this.getSchedulerConfiguration();
      this.getStudioConfiguration();
    } else {
      this.resetData();
    }

    this.commonService.trackSegmentEvent(
      "scheduler_window_opened",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
  }

  /**
   * @method getInitAvailability
   * @description call default
   */
  getInitAvailability() {
    let lastDates = this._timeZoneService.getMonthsLastDate(this.calendarView);
    if (this.calendarView.getMonth() == new Date().getMonth()) {
      this.getAvailability(
        this.selectedTimezone,
        moment(new Date()).format("yyyy-MM-DD"),
        lastDates.last
      );
    } else {
      this.getAvailability(
        this.selectedTimezone,
        lastDates.first,
        lastDates.last
      );
    }
    this.getMoreInfo();
  }

  getMoreInfo() {
    this.skeletonInfoLoaderVisible = true;
    if (!this.schedulerInfo) {
      let isStore = false;
      let subStatus = "";
      if (this.appDataService.urlParameters.store) {
        isStore = true;
      }
      if (this.appDataService.urlParameters.sub_status) {
        const recivedSubStatus = this.appDataService.urlParameters.sub_status;
        subStatus =
          recivedSubStatus === "rescheduled"
            ? "rescheduled"
            : recivedSubStatus === "followup_rescheduled"
            ? "followup_rescheduled"
            : recivedSubStatus === "followup"
            ? "followup"
            : "";
      }
      this.schedulerService
        .getMoreInfo(
          this.selectedTimezone,
          this.callType,
          isStore,
          this.appDataService.urlParameters.email,
          this.appDataService.urlParameters.manager_email,
          this.appDataService.urlParameters.enterprise_tag,
          subStatus,
          this.appDataService.urlParameters.channel_partner,
          this.appDataService.urlParameters.parent_builder_spec_call_id
        )
        .subscribe(
          (res) => {
            this.schedulerInfo = res.data;
            this.handleAdditionalInfo();
          },
          (error) => {
            this.skeletonInfoLoaderVisible = false;
          }
        );
    } else {
      this.handleAdditionalInfo();
    }
  }

  handleAdditionalInfo() {
    //Slots booking Info
    let formattedSlotTime = "";
    if (this.schedulerInfo?.slots_starts) {
      const convertedDateTime = moment2
        .utc(this.schedulerInfo.slots_starts)
        .tz(this.selectedTimezone);
      formattedSlotTime = convertedDateTime.format("DD MMM, YYYY hh:mm a");
      let z1 = convertedDateTime.format("Z");
      let z2 = convertedDateTime.format("z");
      let tzAbbr =
        z1.substring(0, 3) == z2.substring(0, 3)
          ? " (" + z1 + " GMT)"
          : " " + z2;
      formattedSlotTime = formattedSlotTime + tzAbbr;
    }

    //Active Time Range Info
    this.setActiveRange();

    //Slots Update Info
    let lastSlotUpdated = "";
    const lastSlotTime = moment2
      .utc(this.schedulerInfo?.cron_data?.[this.callType]?.last_run)
      .tz(this.selectedTimezone);
    lastSlotUpdated = lastSlotTime.format("hh:mm a");
    let ls1 = lastSlotTime.format("Z");
    let ls2 = lastSlotTime.format("z");
    let lsAbbr =
      ls1.substring(0, 3) == ls2.substring(0, 3)
        ? " (" + ls1 + " GMT)"
        : " " + ls2;
    lastSlotUpdated = lastSlotUpdated + lsAbbr;

    let nextSlotUpdated = "";
    const nextSlotTime = moment2
      .utc(this.schedulerInfo?.cron_data?.[this.callType]?.next_run)
      .tz(this.selectedTimezone);
    nextSlotUpdated = nextSlotTime.format("hh:mm a");
    let ns1 = nextSlotTime.format("Z");
    let ns2 = nextSlotTime.format("z");
    let nsAbbr =
      ns1.substring(0, 3) == ns2.substring(0, 3)
        ? " (" + ns1 + " GMT)"
        : " " + ns2;
    nextSlotUpdated = nextSlotUpdated + nsAbbr;

    this.schedulerInfo = {
      ...this.schedulerInfo,
      lastSlotUpdated,
      nextSlotUpdated,
      formattedSlotTime,
    };

    this.skeletonInfoLoaderVisible = false;
  }

  setActiveRange() {
    if (
      this.schedulerInfo.user_data &&
      this.schedulerInfo.user_data.length &&
      this.selectedDate
    ) {
      let activeTimeRange = [];
      let activeTzAbbr;
      let slots = [];
      for (let slotDate of this.schedulerInfo.user_data) {
        let selectedDateFormat = moment(this.selectedDate).format("YYYY-MM-DD");
        let fromDate = moment
          .utc(new Date(slotDate.from))
          .tz(this.selectedTimezone);
        let toDate = moment
          .utc(new Date(slotDate.to))
          .tz(this.selectedTimezone);
        let selectedDate = moment
          .utc(selectedDateFormat)
          .tz(this.selectedTimezone);
        const isDateInRange = selectedDate.isBetween(
          fromDate,
          toDate,
          null,
          "[]"
        );
        if (isDateInRange) {
          slots = slotDate.time_slots;
          // break;
          for (let slot of slots) {
            let currentDate = new Date().toISOString().slice(0, 10);
            slot = {
              ...slot,
              to: currentDate + slot?.to.slice(10),
              from: currentDate + slot?.from.slice(10),
            };
            let momentTz = moment2.utc(slot.to).tz(this.selectedTimezone);
            let fromTime = moment2
              .utc(slot.from)
              .tz(this.selectedTimezone)
              .format("hh:mm a");
            let toTime = moment2
              .utc(slot.to)
              .tz(this.selectedTimezone)
              .format("hh:mm a");
            let activeZone1 = momentTz.format("Z");
            let activeZone2 = momentTz.format("z");
            activeTzAbbr =
              activeZone1.substring(0, 3) == activeZone2.substring(0, 3)
                ? " (" + activeZone1 + " GMT)"
                : " " + activeZone2;
            toTime = toTime + activeTzAbbr;
            activeTimeRange.push(`${fromTime} - ${toTime}`);
          }
        }
      }
      let combineAllTimes = this.combineTimes(activeTimeRange, activeTzAbbr);

      this.schedulerInfo = {
        ...this.schedulerInfo,
        activeTimeRange: combineAllTimes,
      };
    }
  }

  combineTimes(times: string[], activeTzAbbr): string[] {
    const format = "hh:mm a";

    // Parse the time ranges into an array of objects
    const timeRanges = times.map((time) => {
      const [start, end] = time.split(" - ");
      return {
        start: moment(start, format),
        end: moment(end, format),
      };
    });

    // Sort the time ranges by start time
    timeRanges.sort((a, b) => a.start.diff(b.start));

    // Merge overlapping time ranges
    const mergedRanges = [];
    let currentRange = timeRanges[0];

    for (let i = 1; i < timeRanges.length; i++) {
      const nextRange = timeRanges[i];
      if (currentRange.end.isSameOrAfter(nextRange.start)) {
        // Merge the ranges
        currentRange.end = moment.max(currentRange.end, nextRange.end);
      } else {
        // Push the current range and start a new one
        mergedRanges.push(currentRange);
        currentRange = nextRange;
      }
    }
    // Push the last range
    mergedRanges.push(currentRange);

    // Format the merged ranges back into strings
    return mergedRanges.map(
      (range) =>
        `${range.start.format(format)} - ${range.end.format(
          format
        )} ${activeTzAbbr}`
    );
  }
  /**
   * @method setDateShow
   * @description For setting the date format i.e. Monday, March 13
   */
  setDateShow() {
    this.selectedDateShow = moment(this.selectedDate).format("dddd, MMMM DD");
  }
  /**
   * @method calendarChange
   * @description event capturing on calendar view changes
   * @param event : IMyCalendarViewChanged
   */
  calendarChange(event: IMyCalendarViewChanged) {
    this.showShimmerMonth = moment(event).subtract(1, "days").format("MMM");
    this.showShimmerYear = moment(event).format("YYYY");
    this.chooseAnotherSlot = false;
    this.commonService.trackSegmentEvent(
      "month_navigator_clicked",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
    if (this.onlyOnce) {
      this.onlyOnce = false;
      return;
    }
    this.selectedTimeSlot = null;

    // console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
    let d = new Date();
    if (event.year == d.getFullYear() && event.month > d.getMonth() + 1) {
      // check if greater than current date than only fetch ;
      this.calendarView = new Date();
      this.calendarView.setFullYear(event.year, event.month - 1, 1);
      let lastDates = this._timeZoneService.getMonthsLastDate(
        this.calendarView
      );
      this.modelDate = {
        isRange: false,
        singleDate: {
          date: {
            year: parseInt(lastDates.first.split("-")[0]),
            month: parseInt(lastDates.first.split("-")[1]),
            day: parseInt(lastDates.first.split("-")[2]),
          },
        },
      };
      this.selectedDate = new Date(lastDates.first);
      this.getAvailability(
        this.selectedTimezone,
        lastDates.first,
        lastDates.last
      );
      this.getMoreInfo();
    } else if (
      event.year == d.getFullYear() &&
      event.month == d.getMonth() + 1
    ) {
      this.calendarView = new Date();
      this.calendarView.setFullYear(event.year, event.month - 1, 1);
      let lastDates = this._timeZoneService.getMonthsLastDate(
        this.calendarView
      );
      this.modelDate = {
        isRange: false,
        singleDate: {
          date: {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate(),
          },
        },
      };
      this.selectedDate = new Date(moment(d).format("yyyy-MM-DD"));
      this.getAvailability(
        this.selectedTimezone,
        moment(d).format("yyyy-MM-DD"),
        lastDates.last
      );
      this.getMoreInfo();
      this.setTimeSlots(this.selectedDate);
    } else if (event.year > d.getFullYear()) {
      this.calendarView = new Date();
      this.calendarView.setFullYear(event.year, event.month - 1, 1);
      let lastDates = this._timeZoneService.getMonthsLastDate(
        this.calendarView
      );
      this.getAvailability(
        this.selectedTimezone,
        lastDates.first,
        lastDates.last
      );
      this.getMoreInfo();
    }
    this.setDateShow();
  }

  ngAfterViewInit() {}

  /**
   * @method onDateChange
   * @description Update the selected date on change of calendar
   * @param IMyDateModel : event date
   */
  onDateChange(event: IMyDateModel) {
    this.chooseAnotherSlot = false;
    this.commonService.trackSegmentEvent(
      "available_date_slot_selected",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
    this.selectedTimeSlot = null;
    this.slots = [];
    this.selectedDate = event.singleDate.jsDate;
    let selectDate = moment(this.selectedDate);
    this.selectedDateShow = selectDate.format("dddd, MMMM DD");
    // this.calendarView.getFullYear()>this.selectedDate.getFullYear() ||
    if (this.calendarView.getMonth() == this.selectedDate.getMonth()) {
      this.setTimeSlots(this.selectedDate);
      this.getMoreInfo();
    } else {
      this.calendarView = new Date(this.selectedDate);
      let lastDates = this._timeZoneService.getMonthsLastDate(
        this.selectedDate
      );
      this.getAvailability(
        this.selectedTimezone,
        lastDates.first,
        lastDates.last
      );
      this.getMoreInfo();
    }

    this.commonService.trackSegmentEvent(
      "scheduler_date_selected",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
  }

  getHeading(data) {
    const title = `${data.heading} - `;
    let meetingTime = "";
    const getMeetingTimeByCondition =
      data.meeting_type === "spec" &&
      this.appDataService.urlParameters.enterprise_tag === "true";
    meetingTime = this.minHourFormat(
      getMeetingTimeByCondition
        ? data.sub_types.enterprise.meeting_time
        : data.sub_types?.standard?.meeting_time
        ? data.sub_types?.standard?.meeting_time
        : data.meeting_time
    );
    return `${title}${meetingTime}`;
  }

  getHeadingForManager() {
    let name = this.appDataService.urlParameters.manager_name;
    let callType = this.toTitleCase(
      this.appDataService.urlParameters.call_type
    );
    let heading = `${callType} call with ${name}`;
    return heading;
  }

  toTitleCase(input: string): string {
    if (input) {
      return input.charAt(0).toUpperCase() + input.substring(1).toLowerCase();
    } else return "";
  }

  getSubHeading(data) {
    let meetingTime = "";
    const getMeetingTimeByCondition =
      data.meeting_type === "spec" &&
      this.appDataService.urlParameters.enterprise_tag === "true";
    meetingTime = this.minHourFormat(
      getMeetingTimeByCondition
        ? data.sub_types.enterprise.meeting_time
        : data.sub_types?.standard?.meeting_time
        ? data.sub_types?.standard?.meeting_time
        : data.meeting_time
    );
    return `Call will be booked for ${meetingTime}`;
  }

  /**
   * @method getSchedulerConfiguration
   * @description api hit with call type for setting the configuration
   */
  getSchedulerConfiguration() {
    this.skeletonLoaderVisible = true;
    this.schedulerService
      .getSchedulerConfiguration(this.callType)
      .subscribe((res) => {
        if (res.data && res.data.schedulerconfiguration) {
          this.commonService.call_header =
            this.showAdditionalInfo &&
            this.appDataService.urlParameters.manager_name
              ? this.getHeadingForManager()
              : this.getHeading(res.data.schedulerconfiguration);

          this.commonService.call_description =
            this.showAdditionalInfo &&
            this.appDataService.urlParameters.manager_name
              ? this.getSubHeading(res.data.schedulerconfiguration)
              : res.data.schedulerconfiguration.sub_heading;

          this.commonService.show_avatar =
            this.showAdditionalInfo &&
            this.appDataService.urlParameters.manager_name
              ? false
              : true;

          this.commonService.description_text =
            res.data.schedulerconfiguration.description_text;
          this.commonService.meeting_time =
            res.data.schedulerconfiguration.meeting_time;

          if (res.data.schedulerconfiguration.office_hours) {
            const timeStartEnd =
              res.data.schedulerconfiguration.office_hours.split("-");
            const startTime = timeStartEnd[0].trim();
            const endTime = timeStartEnd[1].trim();
            this.commonService.officeHoursStartTime = startTime;
            this.commonService.officeHoursEndTime = endTime;
          }

          this.commonService.formRequiredFieldConfig = {} as any;
          this.commonService.formRequiredFieldConfig.company =
            res.data.schedulerconfiguration.company;
          this.commonService.formRequiredFieldConfig.product_bx =
            res.data.schedulerconfiguration.description;
        }
      },
      (error) => {
        this.loaderService.hide();
      }
    );
  }
  /**
   * @method getAvailability
   * @description Gets time slots
   * @param month
   * @param timezone
   * @param start_date
   * @param end_date
   */
  getAvailability(timezone, start_date, end_date) {
    this.chooseAnotherSlot = false;
    this.skeletonLoaderVisible = true;

    let isStore = false;
    let subStatus = "";
    if (this.appDataService.urlParameters.store) {
      isStore = true;
    }
    if (this.appDataService.urlParameters.sub_status) {
      const recivedSubStatus = this.appDataService.urlParameters.sub_status;
      subStatus =
        recivedSubStatus === "rescheduled"
          ? "rescheduled"
          : recivedSubStatus === "followup_rescheduled"
          ? "followup_rescheduled"
          : recivedSubStatus === "followup"
          ? "followup"
          : "";
    }
    this.schedulerService
      .getAvailability(
        timezone,
        start_date,
        end_date,
        this.callType,
        isStore,
        this.appDataService.urlParameters.email,
        this.appDataService.urlParameters.generic_id,
        this.appDataService.urlParameters.manager_email,
        this.appDataService.urlParameters.enterprise_tag,
        subStatus,
        this.appDataService.urlParameters.channel_partner,
        this.appDataService.urlParameters.parent_builder_spec_call_id
      )
      .subscribe(
        (res) => {
          this.monthTimeSlot = res.data;
          this.setCalendarDates(this.monthTimeSlot);
          this.commonService.monthTimeSlot = res.data;
          this.skeletonLoaderVisible = false;
          if (this.selectedDate.getMonth() > new Date().getMonth()) {
            this.selectedDate = new Date(res?.data[0]?.date);
            this.modelDate = {
              isRange: false,
              singleDate: {
                date: {
                  year: parseInt(res?.data[0]?.date?.split("-")[0]),
                  month: parseInt(res?.data[0]?.date?.split("-")[1]),
                  day: parseInt(res?.data[0]?.date?.split("-")[2]),
                },
              },
            };
          }
          this.setDateShow();
          this.setTimeSlots(this.selectedDate);
        },
        (error) => {
          this.skeletonLoaderVisible = false;
          let d = new Date(); // Date object for Disable the calendar
          d.setDate(d.getDate() - 1);
          let after3Month = new Date();
          after3Month.setMonth(after3Month.getMonth() + 3).toLocaleString();
          this.myDatePickerOptions = {
            dateFormat: "dd.mm.yyyy",
            disableUntil: {
              year: d.getFullYear(),
              month: d.getMonth() + 1,
              day: d.getDate(),
            },
            disableSince: {
              year: after3Month.getFullYear(),
              month: after3Month.getMonth(),
              day: after3Month.getDate(),
            },
            monthSelector: true,
            disableHeaderButtons: false,
            inline: true,
          };
        }
      );
  }

  /**
   * @method timezoneChange
   * @description Updating the selected timezone
   * @param event contains the target.vale of drop down
   */
  timezoneChange(event) {
    this.chooseAnotherSlot = false;
    this.selectedTimeSlot = null;
    this.slots = [];
    this._timeZoneService.currentTimeZone = this.selectedTimezone;
    let lastDates = this._timeZoneService.getMonthsLastDate(this.calendarView);
    if (this.calendarView.getMonth() == new Date().getMonth()) {
      this.getAvailability(
        this.selectedTimezone,
        moment(new Date()).format("yyyy-MM-DD"),
        lastDates.last
      );
    } else {
      this.getAvailability(
        this.selectedTimezone,
        lastDates.first,
        lastDates.last
      );
    }
    this.getMoreInfo();
    this.commonService.trackSegmentEvent(
      "scheduler_timezone_selected",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
  }
  /**
   * @method selectTimeSlot
   * @description Update the selected time
   * @param timeSlot String
   */
  selectTimeSlot(timeSlot: Slot) {
    this.commonService.trackSegmentEvent(
      "scheduler_timezone_selected",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
    this.commonService.trackSegmentEvent(
      "available_time_slot_selected",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
    this.chooseAnotherSlot = false;
    this.selectedTimeSlot = timeSlot;

    this.commonService.trackSegmentEvent(
      "scheduler_time_selected",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
  }
  /**
   * @method confirmAvailability
   * @description Confirming the slot selected
   *
   */
  confirmAvailability() {
    this.chooseAnotherSlot = false;
    this.validError = false;
    this.commonService.trackSegmentEvent(
      "scheduler_confirm_clicked",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );

    if (this.callType == "spec") {
      this.commonService.trackSegmentEvent(
        "spec_call_requested",
        { user_id: this.appDataService.urlParameters.email },
        {
          call_source: this.callType,
          screen_source: this.appDataService.urlParameters.screen_source,
          studio_version: this.appDataService.urlParameters.studio_version,
          source: this.appDataService.urlParameters.source,
        }
      );
    } else if (this.callType == "demo") {
      this.commonService.trackSegmentEvent(
        "demo_call_requested",
        { user_id: this.appDataService.urlParameters.email },
        {
          call_source: this.callType,
          screen_source: this.appDataService.urlParameters.screen_source,
          studio_version: this.appDataService.urlParameters.studio_version,
          source: this.appDataService.urlParameters.source,
        }
      );
    }
    if (this.selectedTimeSlot && this.selectedDate && this.selectedTimezone) {
      let availabilitySlot: AvailabilitySlot = {
        selectedTime: this.selectedTimeSlot,
        selectedDate: this.selectedDate,
        timezone: this.selectedTimezone,
      };
      // sessionStorage.setItem('availabilitySlot', JSON.stringify(availabilitySlot))
      this.commonService.availabilityObject = availabilitySlot;
      this.commonService.schedulerInfo = this.schedulerInfo;
      if (
        this.appDataService.urlParameters.sub_status == "rescheduled" ||
        this.appDataService.urlParameters.sub_status == "followup_rescheduled"
      ) {
        this.rescheduleCallModal.show();
      } else {
        if (this.selectedTimeSlot && this.selectedDate) {
          this.loaderService.show();
          let isStore = false;
          if (this.appDataService.urlParameters.store) {
            isStore = true;
          }

          const parent_builder_spec_call_id =
            this.appDataService.urlParameters.parent_builder_spec_call_id;
          const sub_status = this.appDataService.urlParameters.sub_status;
          const sub_status_origin =
            this.appDataService.urlParameters.sub_status_origin;

          let scheduleMeta;
          if (this.appDataService.urlParameters.scheduled_by_meta) {
            scheduleMeta = JSON.parse(
              this.appDataService.urlParameters.scheduled_by_meta
            );
            this.commonService.setScheduledByMetaBehavior(scheduleMeta);
          }

          this.schedulerService
            .checkAvailability(
              this.callType,
              moment(this.selectedDate).format("yyyy-MM-DD"),
              this.selectedTimeSlot.start_time,
              this.selectedTimezone,
              isStore,
              this.appDataService.urlParameters.generic_id,
              this.appDataService.urlParameters.email,
              parent_builder_spec_call_id,
              sub_status,
              sub_status_origin,
              this.appDataService.urlParameters.manager_email,
              scheduleMeta ? scheduleMeta : null,
              this.appDataService.urlParameters.name
            )
            .subscribe(
              (res) => {
                this.loaderService.hide();
                if (res && res.booking && res.booking.id) {
                  this.commonService.start_time_in_all_timezone =
                    res.booking.start_time_in_all_timezone;
                  this.commonService.start_time_slot =
                    moment2
                      .utc(res.booking.start_time_slot)
                      .tz(res.booking.time_zone)
                      .format("lll") +
                    " (GMT" +
                    moment2.tz(res.booking.time_zone).format("Z") +
                    ")";

                  if (res.booking.spec_calls) {
                    this.commonService.followUpSpecObject = {
                      name: res.booking.spec_calls.client_name,
                      email: res.booking.spec_calls.client_email,
                      description: res.booking.spec_calls.description,
                      phone: res.booking.spec_calls.phone,
                      company: res.booking.spec_calls.company,
                      participants: [],
                    };
                  }

                  this.commonService.bookingId = res.booking.id;
                  this.commonService.messagePreferenceObject =
                    res.booking.message_preference;
                  this.commonService.specCallInitializationTime =
                    res.booking.created_at;
                  this.router.navigate(["schedule-form"]);
                } else {
                  this.chooseAnotherSlot = true;
                }
              },
              (error) => {
                this.chooseAnotherSlot = true;
                if (error && error.error && error.error.uniqueness) {
                  this.uniqueError = error.error.uniqueness;
                } else {
                  this.uniqueError = "";
                }
                this.loaderService.hide();
              }
            );
        }
      }
    } else {
      this.validError = true;
    }
  }

  confirmReschduleCallModal() {
    this.loaderService.show();
    let isStore = false;
    if (this.appDataService.urlParameters.store) {
      isStore = true;
    }

    const sub_status = this.appDataService.urlParameters.sub_status;
    const sub_status_origin =
      this.appDataService.urlParameters.sub_status_origin;

    this.hideReschduleCallModal();
    let scheduleMeta;
    if (this.appDataService.urlParameters.scheduled_by_meta) {
      scheduleMeta = JSON.parse(
        this.appDataService.urlParameters.scheduled_by_meta
      );
      this.commonService.setScheduledByMetaBehavior(scheduleMeta);
    }
    this.schedulerService
      .reschduleAvailability(
        moment(this.selectedDate).format("yyyy-MM-DD"),
        this.selectedTimeSlot.start_time,
        this.selectedTimezone,
        this.callType,
        sub_status,
        sub_status_origin,
        this.appDataService.urlParameters.generic_id,
        this.appDataService.urlParameters.manager_email,
        scheduleMeta ? scheduleMeta : null
      )
      .subscribe(
        (res) => {
          this.loaderService.hide();
          if (res.message == "success") {
            if (
              res.data.calenderbooking &&
              res.data.calenderbooking.user &&
              res.data.calenderbooking.user.first_name
            )
              this.commonService.userName =
                res.data.calenderbooking.user.first_name +
                " " +
                res.data.calenderbooking.user.last_name;
            this.commonService.location = res.data.calenderbooking.location;
            this.commonService.bookingId = res.data.calenderbooking.id;
            this.commonService.start_time_in_all_timezone =
              res.data.calenderbooking.start_time_in_all_timezone;
            this.commonService.start_time_slot =
              moment2
                .utc(res.data.calenderbooking.start_time_slot)
                .tz(res.data.calenderbooking.time_zone)
                .format("lll") +
              " (GMT" +
              moment2.tz(res.data.calenderbooking.time_zone).format("Z") +
              ")";
            this.router.navigate(["schedule-success"], { replaceUrl: true });
          } else {
            this.chooseAnotherSlot = true;
          }
        },
        (error) => {
          this.chooseAnotherSlot = true;
          if (error && error.error && error.error.uniqueness) {
            this.uniqueError = error.error.uniqueness;
          } else {
            this.uniqueError = "";
          }
          this.loaderService.hide();
        }
      );
  }

  hideReschduleCallModal() {
    this.rescheduleCallModal.hide();
  }
  /**
   * @method close
   * @description cancel or reset the calendar state
   */
  close() {
    // reset the data
    this.resetData();
    this.modelDate = {
      isRange: false,
      singleDate: {
        date: {
          year: this.selectedDate.getFullYear(),
          month: this.selectedDate.getMonth() + 1,
          day: this.selectedDate.getDate(),
        },
      },
    };

    this.commonService.trackSegmentEvent(
      "scheduler_reset_clicked",
      { user_id: this.appDataService.urlParameters.email },
      {
        call_source: this.callType,
        screen_source: this.appDataService.urlParameters.screen_source,
        studio_version: this.appDataService.urlParameters.studio_version,
        source: this.appDataService.urlParameters.source,
      }
    );
  }
  /**
   * @method resetData
   * @description reset the calendar state
   */
  resetData() {
    //reset
    this.validError = false;
    this.chooseAnotherSlot = false;
    this.selectedTimezone = moment2.tz.guess();
    if (this.selectedDate) {
      this.calendarView = this.selectedDate;
    } else {
      this.selectedDate = new Date();
      this.calendarView = new Date();
    }
    this.setDateShow();
    this.selectedTimeSlot = null;
    this.schedulerInfo = null;
    this.getInitAvailability();
  }

  /**
   * @description setting days time slots from month's slots
   */
  setTimeSlots(currentDate: Date) {
    this.slots = [];
    let _currentDate = moment(currentDate).format("yyyy-MM-DD");
    let _index = this.monthTimeSlot.findIndex((res) => {
      return res.date == _currentDate;
    });
    if (_index > -1 && this.monthTimeSlot.length > 0) {
      this.slots = this.monthTimeSlot[_index].time;
      if (this.webSocketConnected) {
        this.calculateDisabledSlots();
      }
      this.slots = this.slots.sort((a: any, b: any) => {
        let d1 = new Date(a.start_time).getTime();
        let d2 = new Date(b.start_time).getTime();
        return d1 - d2;
      });
    }
  }
  /**
   * @method timezoneConversion
   * @description change the time zone
   * @param time
   */
  timezoneConversion(time) {
    return moment(time).tz(this.selectedTimezone).format("hh:mm A");
  }
  /**
   * @method setCalendarDates
   * @description enable and disable dates
   * @param slots
   */
  setCalendarDates(monthsSlots) {
    let lastDate = this._timeZoneService.getMonthsLastDate(this.calendarView);
    let selectedFirstDate;
    if (lastDate) {
      selectedFirstDate = lastDate.first;
    }
    let enabledDates = [];
    monthsSlots.map((item) => {
      let dateData = item.date.split("-");
      let firstDate;
      if (selectedFirstDate) {
        firstDate = selectedFirstDate.split("-");
      } else {
        firstDate = monthsSlots[0].date.split("-");
      }
      // todo check added in last
      if (Number(dateData[1]) == Number(firstDate[1])) {
        enabledDates.push({
          year: Number(dateData[0]),
          month: Number(dateData[1]),
          day: Number(dateData[2]),
        });
      }
    });

    let d = new Date(); // Date object for Disable the calendar
    d.setDate(d.getDate() - 1);
    this.myDatePickerOptions = {
      dateFormat: "dd.mm.yyyy",
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
      disableSince: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
      enableDates: enabledDates,
      monthSelector: true,
      disableHeaderButtons: false,
      inline: true,
    };
  }

  minHourFormat(min) {
    if (min) {
      let _min = Number(min);
      if (_min > 59) {
        let hour = _min / 60;
        let result = Math.floor(hour) + " Hour(s)";
        if (_min % 60 != 0) {
          result += " " + (_min % 60) + " Min(s)";
        }
        return result;
      } else {
        return _min + " min(s)";
      }
    } else {
      return " ";
    }
  }

  /**
   * @method getStudioConfiguration
   * @description api hit for setting the configuration
   */
  getStudioConfiguration() {
    this.schedulerService.getStudioConfiguration().subscribe(
      (configuration) => {
        if (configuration && Array.isArray(configuration.productologists)) {
          const productologists = configuration.productologists;
          this.commonService.call_productologist_avatar_url =
            productologists[Math.floor(Math.random() * productologists.length)];
        }
      },
      (error) => {}
    );
  }

  inItDisableSlotWebsocket() {
    this.commonService.disableSlotWebSocketSub$.asObservable()
      .pipe(takeUntil(this.takeUntilSubscription))
      .subscribe((message: { action: string; message: any }) => {
        console.log(message);
        if (
          message.action == "received" &&
          message.message["data"] &&
          message.message["data"]["booking_type"] === this.callType &&
          (this.appDataService.urlParameters.enterprise_tag === "true"
            ? "enterprise"
            : "standard") === message.message["data"]["booking_sub_type"] &&
          (this.appDataService.urlParameters.vip
            ? JSON.parse(this.appDataService.urlParameters.vip)
            : false) === message.message["data"]["vip"] &&
          (this.appDataService.urlParameters.cip
            ? JSON.parse(this.appDataService.urlParameters.cip)
            : false) === message.message["data"]["cip"]
        ) {
          this.disabledSlots = message.message["data"]["slots"].map((t) =>
            moment.utc(t)
          );
          this.webSocketConnected = true;
          this.calculateDisabledSlots();
          if (
            this.selectedTimeSlot &&
            this.isDisabledSlot(this.selectedTimeSlot.start_time)
          ) {
            this.selectedTimeSlot = null;
          }
        }
      });
  }

  private isDisabledSlot(slot: any): boolean {
    return this.disabledSlots.some((t) => t.isSame(moment(slot).utc()));
  }

  private calculateDisabledSlots() {
    this.slots = this.slots.map((slot: Slot) => {
      if (this.isDisabledSlot(slot.start_time)) {
        slot.disabled = true;
      } else {
        slot.disabled = false;
      }
      return slot;
    });
  }

  onSubTypeChange(subType) {
    this.selectedSubTypeFilter = subType;
    if(subType === 'VIP/CIP') {
      this.appDataService.urlParameters.cip = 'true';
      this.appDataService.urlParameters.vip = 'true';
    } else if(subType === 'VIP') {
      this.appDataService.urlParameters.cip = 'false';
      this.appDataService.urlParameters.vip = 'true';
    } else if(subType === 'CIP') {
      this.appDataService.urlParameters.cip = 'true';
      this.appDataService.urlParameters.vip = 'false';
    } else {
      this.appDataService.urlParameters.cip = 'false';
      this.appDataService.urlParameters.vip = 'false';
    }
    this.getInitAvailability();
  }

  @HostListener('document:click', ['$event'])
  offClickHandler(event:any) {
      if (this.subTypeFilterContainer && !this.subTypeFilterContainer?.nativeElement.contains(event.target)) { 
          this.showSubTypeDdl = false;
      }
  }

  ngOnDestroy(): void {
    this.takeUntilSubscription.next(null);
    this.takeUntilSubscription.complete();
  }
}
