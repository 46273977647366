<div
  [ngStyle]="{
    display: (_loaderService.showPageLoader | async) ? 'none' : 'block'
  }"
>
  <div class="bcl-main-work">
    <div class="second-screen">
      <!--second screen here-->
      <div class="bcl-main-heading bcl-common-heading">
        <p class="m-0">
          <em class="icon" (click)="cancelCall()"
            ><img src="../../../assets/images/bcl-left-arrow.svg" alt="" /></em
          >{{ readOnly ? 'Your details' : 'Enter your details'}}
        </p>
        <div *ngIf="displayTimer" class="timer-display">
          Session times out in: <span class="time">{{ displayTimer }}</span>
        </div>
      </div>
      <div class="bcl-main-from">
        <form #schedulerForm="ngForm">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group floating-input-wrapper">
                <input *ngIf="!readOnly"
                  id="name"
                  type="text"
                  name="name"
                  [(ngModel)]="scheduling.name"
                  placeholder=" "
                  required=""
                  autocomplete="off"
                  maxlength="100"
                  pattern="[a-zA-Z0-9 ]+"
                  class="common-input text-capitalize"
                  #name="ngModel"
                  [readonly]="readOnly"
                />
                <label for="firstName" [ngClass]="readOnly ? 'normal-label' : 'floating-label'"
                  >Full Name <sup *ngIf="!readOnly">*</sup></label
                >
                <div *ngIf="readOnly" class="text-box">{{scheduling.name ? scheduling.name : '-'}}</div>
                <ng-container *ngIf="!readOnly">
                  <div *ngIf="name?.invalid" class="invalid-feedback">
                    Only alphanumeric characters are allowed
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group floating-input-wrapper">
                <input *ngIf="!readOnly"
                  id="email_bx"
                  placeholder=" "
                  type="email"
                  name="email"
                  [(ngModel)]="scheduling.email"
                  (ngModelChange)="internalEmailErr = false"
                  maxlength="100"
                  pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$"
                  required=""
                  autocomplete="off"
                  #email="ngModel"
                  class="common-input"
                  [readonly]="readOnly"
                />
                <label for="email_bx" [ngClass]="readOnly ? 'normal-label' : 'floating-label'"
                  >Email <sup *ngIf="!readOnly">*</sup></label
                >
                <div *ngIf="readOnly" class="text-box">{{scheduling.email ? scheduling.email : '-'}}</div>
                <ng-container *ngIf="!readOnly">
                  <div *ngIf="email?.invalid" class="invalid-feedback">
                    Please enter valid email
                  </div>
                  <div *ngIf="internalEmailErr" class="invalid-feedback">
                    Please enter customer email. Internal email is not acceptable.
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group floating-input-wrapper">
                <div *ngIf="!readOnly" class="common-input-box bcl-phone-bx">
                  <div class="sa-phone-box">
                    <div class="country-code-wrp countryCodes" id="countryCode">
                      <international-phone-number
                        placeholder="Enter phone number"
                        [(ngModel)]="scheduling.countryCode"
                        (ngModelChange)="changeCountryCode()"
                        #countryCode="ngModel"
                        name="countryCode"
                        [defaultCountry]="cCodeValue ? cCodeValue : 'in'"
                        [maxlength]="20"
                        #countryCodeValue
                        (input)="validateCountryCode(scheduling.countryCode)"
                        [ngClass]="{ disabled: readOnly }"
                        [allowDropdown]="!readOnly"
                        (keydown)="toMakeDisable($event)"
                      >
                      </international-phone-number>
                    </div>
                  </div>
                  <div class="invalid-feedback" *ngIf="phoneError">
                    {{ phoneError }}
                  </div>
                  <div *ngIf="countryCode?.invalid" class="invalid-feedback">
                    <!-- {{cCodeValue ? 'Please enter valid phone number': 'Please select valid country code'}} -->
                    Please add a valid number.
                  </div>
                </div>
                <label *ngIf="readOnly" for="countryCode" class="normal-label"
                  >Phone</label
                >
                <div *ngIf="readOnly" class="text-box">{{scheduling.countryCode ? scheduling.countryCode : '-'}}</div>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="commonService?.callType !== 'kickoff'">
              <div class="form-group floating-input-wrapper">
                <input *ngIf="!readOnly"
                  id="company_bx"
                  #company="ngModel"
                  placeholder=" "
                  type="text"
                  name="company"
                  [(ngModel)]="scheduling.company"
                  maxlength="100"
                  autocomplete="off"
                  class="common-input"
                  [readonly]="readOnly"
                />
                <label for="company_bx" [ngClass]="readOnly ? 'normal-label' : 'floating-label'"
                  >{{readOnly ? 'Company (or project) name' : 'Name of organisation'}}
                  <!-- <sup *ngIf="commonService?.formRequiredFieldConfig?.company"
                >*</sup
              > -->
                </label>
                <div *ngIf="readOnly" class="text-box">{{scheduling.company ? scheduling.company : '-'}}</div>
                <!-- [required]="commonService?.formRequiredFieldConfig?.company" -->

                <!-- <div
                *ngIf="company.touched && company.invalid"
                class="invalid-feedback"
              >
                Please enter organisation name
              </div> -->
              </div>
            </div>
            <div class="col-sm-12" *ngIf="showDescription">
              <div class="form-group floating-input-wrapper">
                <textarea
                  id="product_bx"
                  name="product_bx"
                  [(ngModel)]="scheduling.comment"
                  #product_bx="ngModel"
                  placeholder=" "
                  class="common-input"
                  maxlength="2000"
                  [required]="
                  !readOnly && commonService?.formRequiredFieldConfig?.product_bx
                  "
                ></textarea>
                <label for="product_bx" class="floating-label full-width"
                  >{{ commonService.description_text }}
                  <sup
                    *ngIf="
                      !readOnly &&
                      commonService?.formRequiredFieldConfig?.product_bx
                    "
                    >*</sup
                  >
                </label>
                <div
                  *ngIf="product_bx?.invalid && !readOnly"
                  class="invalid-feedback"
                >
                  Please enter your product description
                </div>
              </div>
              <div class="form-group file-attachments-wrp">
                <div
                  class="attachment-list"
                  *ngIf="scheduling.attatchmets.length > 0"
                >
                  <div class="attachment-title">
                    <span class="title">Attachments</span>
                    <div class="add-more-participants">
                      <div class="add-more-btn">Add attachment</div>
                      <input
                        type="file"
                        name="attatchmets"
                        (change)="onFileChange($event)"
                        multiple
                        class="file-input"
                        accept="image/*,.doc,.docx,.xls,.xlsx,.pdf,.odt,.csv"
                      />
                    </div>
                  </div>
                  <div class="attachments-list-row">
                    <div
                      class="attachments-list-col"
                      *ngFor="let file of scheduling.attatchmets; let i = index"
                    >
                      <div class="attachment-card">
                        <em class="thumb"
                          ><img
                            src="../../../assets/images/bcl-img-notes.svg"
                            alt=""
                        /></em>
                        <div class="attachment-content">
                          <div class="file-name">{{ file.name }}</div>
                          <div class="file-size">
                            {{
                              file.size
                                ? (file.size / 1024 | number : "1.0-0") + " KB"
                                : ""
                            }}
                          </div>
                        </div>
                        <span
                          class="delete-file"
                          (click)="removeAttatchment(i)"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="file-attatch-main">
                  <div
                    class="file-attach-inner"
                    *ngIf="scheduling.attatchmets.length === 0"
                  >
                    <em class="drop-icon"
                      ><img
                        src="../../../assets/images/bcl-drop-file.svg"
                        alt="File"
                    /></em>
                    <div class="file-attach-content">
                      <span class="link">Browse files</span> to add attachments
                    </div>
                  </div>
                  <input
                    type="file"
                    name="attatchmets"
                    (change)="onFileChange($event)"
                    multiple
                    class="file-input"
                    accept="image/*,.doc,.docx,.xls,.xlsx,.pdf,.odt,.csv"
                  />
                </div>
              </div>
              <div class="invalid-group" *ngIf="attatchmetsError.length > 0">
                <div
                  class="invalid-feedback"
                  *ngFor="let error of attatchmetsError"
                >
                  {{ error.error }}
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="!readOnly && commonService?.callType !== 'kickoff'"
            class="sms-whatsapp-block form-group"
          >
            <!--SMS and WhatsApp block Html-->
            <div class="notification-block">
              <div class="left-title">
                Do you want to receive notifications?
              </div>
              <label class="bcl-custom-switch" *ngIf="!readOnly">
                <input
                  type="checkbox"
                  class="switch-input"
                  name="isReceiveNotification"
                  [(ngModel)]="isReceiveNotification"
                  (change)="changeShowNotification($event)"
                />
                <span class="slider"></span>
              </label>
            </div>
            <div
              class="notification-item"
              [ngClass]="{ active: isReceiveNotification }"
            >
              <div class="notification-item-outer">
                <div class="custom-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="isShowWhatsapp"
                      [(ngModel)]="isShowWhatsapp"
                      (change)="changeShowWhatsapp($event)"
                    />
                    <span class="checkmark"></span> WhatsApp
                  </label>
                </div>

                <div class="form-group">
                  <!-- <label for="phone_num"
                    >WhatsApp Number<sup *ngIf="isShowWhatsapp"> *</sup></label
                  > -->
                  <div class="common-input-box bcl-phone-bx">
                    <div class="sa-phone-box">
                      <div
                        class="country-code-wrp upside countryCodes"
                        id="whatsapp_phone_number"
                      >
                        <international-phone-number
                          placeholder="Enter phone number"
                          [(ngModel)]="scheduling.whatsapp_phone_number"
                          #whatsapp_phone_number="ngModel"
                          name="whatsapp_phone_number"
                          [defaultCountry]="
                            whatsappCCodeValue ? whatsappCCodeValue : 'in'
                          "
                          [maxlength]="20"
                          #whatsappNumberCCodeValue
                        >
                        </international-phone-number>
                      </div>
                    </div>
                    <div
                      *ngIf="whatsapp_phone_number?.invalid"
                      class="invalid-feedback"
                    >
                      Please enter valid phone number
                    </div>
                  </div>
                </div>
              </div>
              <div class="notification-item-outer">
                <div class="custom-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="isShowSMS"
                      [(ngModel)]="isShowSMS"
                      (change)="changeShowSMS($event)"
                    />
                    <span class="checkmark"></span> SMS
                  </label>
                </div>

                <div class="form-group">
                  <!-- <label for="phone_num"
                    >SMS Number<sup *ngIf="isShowSMS"> *</sup></label
                  > -->
                  <div class="common-input-box bcl-phone-bx">
                    <div class="sa-phone-box">
                      <div
                        class="country-code-wrp countryCodes upside"
                        id="sms_phone_number"
                      >
                        <international-phone-number
                          placeholder="Enter phone number"
                          [(ngModel)]="scheduling.sms_phone_number"
                          #sms_phone_number="ngModel"
                          name="sms_phone_number"
                          [defaultCountry]="
                            smsCCodeValue ? smsCCodeValue : 'in'
                          "
                          [maxlength]="20"
                          #smsNumberCCodeValue
                        >
                        </international-phone-number>
                      </div>
                    </div>
                    <div
                      *ngIf="sms_phone_number?.invalid"
                      class="invalid-feedback"
                    >
                      Please enter valid phone number
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!readOnly" class="new-participant-row">
            <!--Enter Participants Html start-->
            <div class="participant-title-block">
              <span class="title"
                >Add your Participants - and make them a part of this
                Buildcard</span
              >
              <div class="add-more-participants">
                <div
                  *ngIf="form.participants.length < 5"
                  class="add-more-btn"
                  (click)="addParticipant()"
                >
                  Add Participant
                </div>
              </div>
            </div>

            <div class="add-teammate-wrap">
              <ng-template
                ngFor
                let-participant
                [ngForOf]="form.participants"
                let-index="index"
                let-isLast="last"
              >
                <div class="teammate-row">
                  <div class="row">
                    <span
                      (click)="removeParticipant(participant.id)"
                      class="add-more-btn delete-row"
                    ></span>
                    <div class="col-sm-6 teammate-col bottom-margin">
                      <div class="form-group m0 floating-input-wrapper">
                        <input
                          id="name"
                          type="text"
                          name="name_{{ participant.id }}"
                          [(ngModel)]="participant.name"
                          placeholder=" "
                          autocomplete="off"
                          maxlength="100"
                          pattern="[a-zA-Z0-9 ]+"
                          class="common-input"
                          #name="ngModel"
                        />
                        <label for="firstName" class="floating-label"
                          >Name</label
                        >
                        <div
                          *ngIf="name.touched && name.invalid"
                          class="invalid-feedback"
                        >
                          Only alphanumeric characters are allowed
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 teammate-col">
                      <div class="form-group m0 floating-input-wrapper">
                        <input
                          id="email_bx"
                          placeholder=" "
                          type="email"
                          name="email_{{ participant.id }}"
                          [(ngModel)]="participant.email"
                          maxlength="100"
                          pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$"
                          autocomplete="off"
                          #email="ngModel"
                          class="common-input"
                        />
                        <label for="email_bx" class="floating-label"
                          >Email address</label
                        >
                        <div
                          *ngIf="email.touched && email.invalid"
                          class="invalid-feedback"
                        >
                          Please enter valid email
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="bcl-common-btn-group">
    <div class="error-warning">
      <!--empty div for handle flex ui-->
      <div *ngIf="message">
        {{ message }}
      </div>
    </div>
    <div class="bcl-btn-grp">
      <button (click)="cancelCall()" class="bcl-common-button bcl-grey-btn">
        Back
      </button>
      <!--for second screen "Back"-->
      <!--for third screen not visible-->
      <button
        (click)="readOnly ? schedule() : schedulerForm.valid && schedule()"
        [disabled]="
          readOnly
            ? disableButton()
            : schedulerForm.invalid ||
              !scheduling.countryCode ||
              this.phoneError
        "
        class="bcl-common-button bcl-green-btn bcl-confirm-btn"
      >
        Schedule
      </button>
      <!--for second screen "Schedule"-->
      <!--for third screen "Resend Invite"-->
    </div>
  </div>
</div>
<!-- Confirm modal start-->
<div
  bsModal
  #sessionTimeoutModal="bs-modal"
  class="client-modal modal fade"
  id="sessionTimeout"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalCenterTitle"
  aria-hidden="true"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal bcl-schedule-box">
      <div class="modal-body">
        <div class="delete-task-block">
          <h4>Session Timeout</h4>
          <div class="delete-task-content">
            <p>Session timed out, please go back and re-select the slots.</p>
          </div>
        </div>
        <div class="bcl-common-btn-group">
          <button
            class="bcl-common-button bcl-green-btn bcl-confirm-btn"
            type="button"
            (click)="goBack()"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Confirm modal ends-->
