import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { Constants } from "../utility/constants";
import { HttpRequests } from "./http-requests.service";
import { AvailabilitySlot, Scheduling } from "../models/interface";
import { throwError } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
/**
 * Created by Anil Suryavanshi on 15/05/20.
 */
@Injectable({ providedIn: "root" })
export class SchedulerService {
  availabilitySlot: AvailabilitySlot;
  scheduling: Scheduling;
  constructor(
    private dataService: DataService,
    private httpRequest: HttpRequests
  ) {}

  getSchedulerConfiguration(callType: string) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.schedulerConfigurations +
      `?type=${callType}`;
    return this.httpRequest.get(
      API,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  getAvailability(
    timezone,
    startDate,
    endDate,
    callType: string,
    isStore: boolean,
    email?: string,
    generic_id?: string,
    manager_email?: string,
    enterprise_tag?: any,
    subStatus?: string,
    channel_partner?: string,
    parent_builder_spec_call_id?: string
  ) {
    let email_check = "";
    let generic_id_check = "";
    let manager_email_check = "";
    let enterprise = "";
    let byPassBfferCheck = "";
    let channelPartner = "";
    let cip = "";
    let vip = "";

    if (manager_email) {
      manager_email_check = `&manager_email=${manager_email}`;
    }
    if (email) {
      email_check = `&email=${email}`;
    }
    if (generic_id) {
      generic_id_check = `&generic_id=${generic_id}`;
    }
    if (callType === "kickoff") {
      if (channel_partner) {
        byPassBfferCheck = `&bypass_days_buffer=true`;
      } else {
        byPassBfferCheck = `&bypass_days_buffer=false`;
      }
    }
    if (this.dataService.urlParameters.see_slots == "true") {
      byPassBfferCheck = `&bypass_days_buffer=true`;
    }
    if (callType === "spec" && enterprise_tag === "true") {
      enterprise = `&sub_type=enterprise`;
    } else {
      enterprise = `&sub_type=standard`;
    }
    if (subStatus) {
      subStatus = `&sub_status=${subStatus}`;
    }
    if (channel_partner) {
      channelPartner = `&channel_partner=${channel_partner}`;
    }
    if (this.dataService.urlParameters.cip == "true") {
      cip = `&cip=true`;
    }
    if (this.dataService.urlParameters.vip == "true") {
      vip = `&vip=true`;
    }
    let API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getAvailability +
      `?type=${callType}&time_zone=${timezone}&start_date=${startDate}&end_date=${endDate}&studio_store=${isStore}${email_check}${generic_id_check}${byPassBfferCheck}${manager_email_check}${enterprise}${subStatus}${channelPartner}${cip}${vip}&parent_builder_spec_call_id=${parent_builder_spec_call_id}`;

    if (this.dataService.urlParameters.country_code) {
      API += `&country_code=${this.dataService.urlParameters.country_code}`;
    }
    if (this.dataService.urlParameters.build_card_type) {
      API += `&build_card_type=${this.dataService.urlParameters.build_card_type}`;
    }

    return this.httpRequest.get(
      API,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  getMoreInfo(
    timezone,
    callType: string,
    isStore: boolean,
    email?: string,
    manager_email?: string,
    enterprise_tag?: any,
    subStatus?: string,
    channel_partner?: string,
    parent_builder_spec_call_id?: string
  ) {
    let email_check = "";
    let manager_email_check = "";
    let enterprise = "";
    let byPassBfferCheck = "";
    let channelPartner = "";
    let cip = "";
    let vip = "";
    let generic_id_check = "";

    if (manager_email) {
      manager_email_check = `&manager_email=${manager_email}`;
    }
    if (this.dataService.urlParameters.generic_id) {
      generic_id_check = `&generic_id=${this.dataService.urlParameters.generic_id}`;
    }
    if (email) {
      email_check = `&email=${email}`;
    }
    if (callType === "kickoff") {
      if (channel_partner) {
        byPassBfferCheck = `&bypass_days_buffer=true`;
      } else {
        byPassBfferCheck = `&bypass_days_buffer=false`;
      }
    }
    if (this.dataService.urlParameters.see_slots == "true") {
      byPassBfferCheck = `&bypass_days_buffer=true`;
    }
    if (callType === "spec" && enterprise_tag === "true") {
      enterprise = `&sub_type=enterprise`;
    } else {
      enterprise = `&sub_type=standard`;
    }
    if (subStatus) {
      subStatus = `&sub_status=${subStatus}`;
    }
    if (channel_partner) {
      channelPartner = `&channel_partner=${channel_partner}`;
    }
    if (this.dataService.urlParameters.cip == "true") {
      cip = `&cip=true`;
    }
    if (this.dataService.urlParameters.vip == "true") {
      vip = `&vip=true`;
    }
    let API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.availability_info +
      `?type=${callType}&time_zone=${timezone}&studio_store=${isStore}${email_check}${byPassBfferCheck}${manager_email_check}${generic_id_check}${enterprise}${subStatus}${channelPartner}${cip}${vip}&parent_builder_spec_call_id=${parent_builder_spec_call_id}`;

    return this.httpRequest.get(
      API,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  checkAvailability(
    callType: string,
    selected_date: String,
    time_slot: string,
    time_zone: string,
    isStore: boolean,
    generic_id?: string,
    email?: string,
    parent_builder_spec_call_id?: string,
    sub_status?: string,
    sub_status_origin?: string,
    manager_email?: string,
    scheduleByMeta?,
    name?
  ) {
    const API =
      this.dataService.getServerUrl() + Constants.apiEndPoints.bookSlot;
    let data = {
      calender_booking: {
        booking_type: callType,
        meeting_date: selected_date,
        start_time_slot: time_slot,
        time_zone: time_zone,
        studio_store: isStore,
      },
    };
    if (this.dataService.urlParameters.cip == "true") {
      data.calender_booking["cip"] = true;
    }
    if (this.dataService.urlParameters.vip == "true") {
      data.calender_booking["vip"] = true;
    }

    if (
      this.dataService.urlParameters.studio_version == "4" ||
      this.dataService.urlParameters.studio_version == "4.0"
    ) {
      data.calender_booking["studio_version"] = "2";
    }
    if (generic_id) {
      data.calender_booking["generic_id"] = generic_id;
    }
    if (email) {
      data.calender_booking["email"] = email;
    }
    if (manager_email) {
      data["manager_email"] = manager_email;
    }
    // in case of followup followup/reschedule
    if (parent_builder_spec_call_id) {
      data.calender_booking["parent_builder_spec_call_id"] =
        parent_builder_spec_call_id;
    }
    if (sub_status) {
      data.calender_booking["sub_status"] = sub_status;
    }
    if (sub_status_origin) {
      data.calender_booking["sub_status_origin"] = sub_status_origin;
    }
    if (scheduleByMeta) {
      data.calender_booking["scheduled_by_meta"] = scheduleByMeta;
    } else {
      data.calender_booking["scheduled_by_meta"] = {
        created: { name: name, email: email, source: "studio" },
      };
    }

    if (
      callType === "spec" &&
      this.dataService.urlParameters.enterprise_tag === "true"
    ) {
      data.calender_booking["booking_sub_type"] = "enterprise";
    } else {
      data.calender_booking["booking_sub_type"] = "standard";
    }

    if (this.dataService.urlParameters.country_code) {
      data.calender_booking['country_code'] = this.dataService.urlParameters.country_code;
    }
    if (this.dataService.urlParameters.build_card_type) {
      data.calender_booking['build_card_type'] = this.dataService.urlParameters.build_card_type;
    }

    return this.httpRequest.post(
      API,
      data,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  scheduleAvailability(
    bookingID,
    selected_date,
    time_slot,
    scheduling,
    type,
    isStore,
    parent_builder_spec_call_id?: string,
    sub_status?: string,
    sub_status_origin?: string,
    generic_id?: string
  ) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.updateSlot +
      `/${bookingID}`;
    const files = scheduling.attatchmets;
    const names = scheduling.name.split(" ");
    const firstName = names[0];
    let secondName = "";
    if (names.length > 1) {
      names.splice(0, 1);
      secondName = names.join(" ");
    }

    // const data = {
    //     calender_booking: {
    //         booking_type:type,
    //         client_first_name:  firstName,
    //         client_last_name:   secondName,
    //         email: scheduling.email,
    //         description:scheduling.comment,
    //         phone_no:scheduling.countryCode,
    //         company: scheduling.company,
    //         studio_store:isStore,
    //         participants: scheduling.participants,
    //     }
    // }

    const data = new FormData();
    data.append("calender_booking[booking_type]", type);
    data.append("calender_booking[client_first_name]", firstName);
    data.append("calender_booking[client_last_name]", secondName);
    data.append("calender_booking[email]", scheduling.email);
    data.append("calender_booking[description]", scheduling.comment);
    data.append("calender_booking[phone_no]", scheduling.countryCode);
    data.append("calender_booking[company]", scheduling.company);
    data.append("calender_booking[studio_store]", isStore);

    data.append(
      "message_preference[sms_phone_number]",
      scheduling.sms_phone_number
        ? scheduling.sms_phone_number
        : scheduling.countryCode.replace("-", "")
    );

    if (scheduling.whatsapp_phone_number) {
      data.append(
        "message_preference[whatsapp_phone_number]",
        scheduling.whatsapp_phone_number
      );
    }

    if (scheduling.whatsapp_phone_number && !scheduling.sms_phone_number) {
      data.append("message_preference[communication_channel]", "whatsapp");
    } else if (
      !scheduling.whatsapp_phone_number &&
      scheduling.sms_phone_number
    ) {
      data.append("message_preference[communication_channel]", "sms");
    } else if (
      scheduling.whatsapp_phone_number &&
      scheduling.sms_phone_number
    ) {
      data.append("message_preference[communication_channel]", "both");
    }
    //data.append('calender_booking[participants][]', JSON.stringify(scheduling.participants));
    // if(scheduling.participants.length > 0){
    //     scheduling.participants.forEach((item,index) => {
    //         data.append('calender_booking[participants][]', item)
    //     })
    // }
    for (var i = 0, valuePair; (valuePair = scheduling.participants[i]); i++)
      for (var j in valuePair)
        data.append(
          "calender_booking[participants][][" + j + "]",
          valuePair[j]
        );
    if (files.length > 0) {
      files.forEach((file, index) => {
        data.append("calender_booking[attachments_attributes][][file]", file);
        const ownerDetails = JSON.stringify({
          name: firstName + " " + secondName,
          email: scheduling.email,
        });
        data.append(
          "calender_booking[attachments_attributes][][owner_details][name]",
          firstName + " " + secondName
        );
        data.append(
          "calender_booking[attachments_attributes][][owner_details][email]",
          scheduling.email
        );
        data.append(
          "calender_booking[attachments_attributes][][owner_type]",
          "Client"
        );
      });
    }

    // in case of followup followup/reschedule
    if (generic_id) {
      data.append("calender_booking[generic_id]", generic_id);
    }
    if (parent_builder_spec_call_id) {
      data.append(
        "calender_booking[parent_builder_spec_call_id]",
        parent_builder_spec_call_id
      );
    }
    if (sub_status) {
      data.append("calender_booking[sub_status]", sub_status);
    }
    if (sub_status_origin) {
      data.append("calender_booking[sub_status_origin]", sub_status_origin);
    }
    
    return this.httpRequest.put(
      API,
      data,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  reschduleAvailability(
    selected_date,
    time_slot,
    time_zone,
    type,
    sub_status?: string,
    sub_status_origin?: string,
    generic_id?: string,
    manager_email?: string,
    scheduleByMeta?
  ) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.updateSlot +
      "/" +
      generic_id + "/booking_type/" + type;
    const data = {
      calender_booking: {
        booking_type: type,
        meeting_date: selected_date,
        start_time_slot: time_slot,
        time_zone: time_zone,
        generic_id: generic_id,
        sub_status: sub_status,
        sub_status_origin: sub_status_origin,
      },
    };

    if (manager_email) {
      data["manager_email"] = manager_email;
    }
    if (scheduleByMeta) {
      data.calender_booking["scheduled_by_meta"] = scheduleByMeta;
    }

    return this.httpRequest.put(
      API,
      data,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  reInvite(bookingID) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.reinvite +
      `/${bookingID}`;

    return this.httpRequest.get(
      API,
      this.dataService.getRequestGenericOptionArgs()
    );
  }

  getStudioConfiguration() {
    const API =
      this.dataService.getServerStudioUrl() +
      Constants.apiEndPoints.studioConfiguration;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      "apptoken",
      this.dataService.getAppTokenForStudio()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
